import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "bf-modal",
  templateUrl: "bf-modal.component.html",
  styleUrls: ["./bf-modal.component.scss"],
})
export class ModalComponent {
  @Input() title: string;
  @Input() modalId: string;
  @Input() closeOnBodyClick = "false";
  @ViewChild("modalContainer") containerEl: ElementRef;
  @Output() onClose = new EventEmitter();

  close() {
    this.onClose.emit();
  }
}
