import { Component, Input } from "@angular/core";

@Component({
  selector: "bf-dark-container",
  templateUrl: "./dark-container.component.html",
  styleUrls: ["./dark-container.component.scss"],
})
export class DarkContainerComponent {
  @Input() title: string;
  @Input() content: any = "<bf-classes></bf-classes>";
  @Input() percentualProgress: string;
  @Input() shrink = false;
}
