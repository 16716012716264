<section>
  <div class="container">
    <div class="row">
      <h1 class="text-center subtitulo TitilliumWeb-Bold">
        {{ title }}
      </h1>
    </div>
    <div class="row topics-group">
      <div
        *ngFor="let item of topics"
        class="col-sm-12 col-md-3 topic"
        data-aos="zoom-out"
        data-aos-offset="320"
        data-aos-duration="500"
      >
        <img
          src="assets/img/icons/{{ item[2] }}"
          width="96"
          height="96"
          alt=""
        />
        <h2>{{ item[0] }}</h2>
        <p>{{ item[1] }}</p>
      </div>
    </div>
  </div>
</section>
