<footer id="footer">
  <div class="container-fluid">
    <div class="footer">
      <div class="text-center">
        <span class="info" i18n="@@medicalInfo"
          >Consulte o seu médico antes de iniciar ou aumentar a prática de
          atividade física ou seguir qualquer dica nutricional</span
        >
      </div>
      <div id="menu" class="menu">
        <ul>
          <li>
            <a
              id="footer-about"
              (click)="showAboutModal(aboutModal)"
              i18n="@@footerAbout"
            >
              SOBRE O BTFIT
            </a>
          </li>
          <li>
            <a
              id="footer-terms"
              target="_blank"
              rel="noreferrer"
              i18n-href="@@termsOfUseLink"
              href="https://policy.bt.fit/pt"
              i18n="@@footerTerms"
            >
              TERMOS DE USO
            </a>
          </li>
          <li>
            <a
              id="footer-terms"
              target="_blank"
              rel="noreferrer"
              i18n-href="@@privacyLink"
              href="https://policy.bt.fit/pt#privacy"
              i18n="@@footerPrivacyPolicy"
            >
              POLÍTICA DE PRIVACIDADE
            </a>
          </li>
          <li>
            <a
              id="footer-contact"
              target="_blank"
              rel="noreferrer"
              i18n-href="@@contactUsLink"
              href="https://bodytech.my.site.com/ajudabtfit/s/contactsupport?language=pt_BR"
              i18n="@@footerContactUs"
            >
              FALE CONOSCO
            </a>
          </li>
          <li>
            <a
              style="text-transform: uppercase"
              id="footer-faq"
              target="_blank"
              rel="noreferrer"
              i18n-href="@@faqLink"
              href="https://bodytech.my.site.com/ajudabtfit/s/?language=pt_BR"
              i18n="@@notFoundFAQ2"
            >
              CENTRAL DE AJUDA
            </a>
          </li>
        </ul>
        <!--div>
          <span>Copyright © 2008 Developed by <a>BTFIT</a></span>
        </div-->
      </div>
      <div class="store">
        <p i18n="@@footerDownloadApp">LEVE O BTFIT COM VOCÊ</p>
        <bf-store-buttons></bf-store-buttons>
      </div>
      <div id="social" class="social">
        <ul>
          <li>
            <a
              id="footer-facebook"
              href="https://www.facebook.com/btfitapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                height="42"
                width="42"
                src="assets/img/icons/facebook_42.webp"
                alt="BTFit no Facebook"
              />
            </a>
          </li>
          <li>
            <a
              id="footer-twitter"
              href="https://twitter.com/btfitapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                height="42"
                width="42"
                src="assets/img/icons/twitter_42.webp"
                alt="BTFit no Twitter"
              />
            </a>
          </li>
          <li>
            <a
              id="footer-youtube"
              href="https://www.youtube.com/channel/UCS5zLy8gC2h7_-PgUeXm0lQ"
              rel="noreferrer"
              target="_blank"
            >
              <img
                loading="lazy"
                height="42"
                width="42"
                src="assets/img/icons/youtube_42.webp"
                alt="BTFit no Youtube"
              />
            </a>
          </li>
          <li>
            <a
              id="footer-instagram"
              href="https://www.instagram.com/btfitapp/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                height="42"
                width="42"
                src="assets/img/icons/instagram_42.webp"
                alt="BTFit no Instagram"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<div class="bt-footer">
  <a
    href="https://www.bodytech.com.br"
    id="footer-home"
    target="_blank"
    rel="noreferrer"
  >
    <img
      loading="lazy"
      height="32"
      width="248"
      alt="Bodytech"
      src="assets/img/img-logo-bodytech.svg"
    />
  </a>
</div>

<ng-template #aboutModal>
  <bf-modal
    (onClose)="closeAboutModal()"
    class="about-modal"
    id="modal"
    modalId="aboutModal"
    i18n-title="@@aboutBTFITTitle"
    title="SOBRE O BTFIT"
  >
    <div class="row">
      <div class="col-md-12 about-list">
        <h3 i18n="@@aboutTitle">Sobre o BTFIT</h3>
        <p i18n="@@About1">
          O BTFIT é a solução para quem deseja treinar onde e quando quiser.
          Prático, personalizado e acessível, o app foi criado em 2015 pela
          Bodytech Company, uma das 15 maiores empresas de fitness do mundo,
          segundo a IHRSA.
        </p>
        <p i18n="@@About2">
          A nossa principal missão é democratizar o acesso à atividade física,
          provando que todos podem sim cuidar da saúde e ter qualidade de vida,
          independente de rotina, lugar ou renda. Na sala de casa, ao ar livre
          ou na academia, o app foi lançado para desmistificar a ideia de que
          falta de tempo ou dinheiro são desculpas para continuar parado no
          sofá.
        </p>
        <p i18n="@@About3">
          Para chegarmos ao BTFIT que temos hoje, foram mais de dois anos de
          muito trabalho, pesquisas e testes. Apostamos na união entre uma
          equipe multidisciplinar, jovem e apaixonada por tecnologia e a
          experiência de uma companhia que há mais de 20 anos faz sucesso no
          mercado fitness. E qual foi o resultado desse encontro? Uma plataforma
          que faz a diferença na vida de quem antes não conseguia cuidar do
          próprio bem-estar.
        </p>
        <h3 i18n="@@differentialTitle">Nosso diferencial</h3>
        <p i18n="@@Differential1">
          Desde o começo do BTFIT, a equipe já tinha várias ideias e muita
          vontade de colocar esses desejos em prática. Nós queríamos levar
          soluções que já davam muito certo na academia para onde as pessoas
          estivessem.
        </p>
        <p i18n="@@Differential2">
          Foi aí que surgiu o plano de ter aulas coletivas inéditas todos os
          dias no app. Mais de cinco mil professores ficaram interessados! O
          grande desafio foi selecionar não só os melhores em Yoga, Workout ou
          Mat Pilates... A gente precisava dos melhores também em frente às
          câmeras, para que quem estivesse do outro lado se sentisse dentro da
          aula. Foi assim que ficamos dia e noite dentro do estúdio para fazer a
          seleção. E deu certo! O BTFIT tem aulas de Yoga, 20 Minute Workout,
          Mat Pilates, Abdominal, CardioDance e Ballet Fitness com professores
          feras em um formato superdinâmico, do jeito que a gente sonhou lá
          trás.
        </p>
        <p i18n="@@Differential3">
          Além das coletivas, nós precisávamos oferecer uma opção exclusiva,
          então criamos o Personal Trainer Online, que só é possível graças a um
          algoritmo desenvolvido e patenteado por nós. Chamado de AutoTraining®,
          ele processa as informações do usuário, como tempo disponível e
          objetivos, e a partir disso gera fichas de treinos 100%
          personalizadas. Foram várias madrugadas gravando mais de 4.000
          exercícios para nossa biblioteca. Ou seja, com esse tanto de opções e
          combinações possíveis, a gente garante que cada pessoa tem um treino
          só seu no BTFIT.
        </p>
        <p i18n="@@Differential4">
          Outra coisa que queríamos muito era que essa exclusividade toda
          garantisse também a possibilidade do usuário acompanhar sua evolução.
          Pesquisamos, testamos muito e desenvolvemos o Meu Perfil. Com ele, dá
          para acompanhar o tempo de treino semanal, o gasto calórico, as
          mudanças nas medidas corporais e o desempenho em cada exercício.
        </p>
        <p i18n="@@Differential5">
          Outra exigência nossa era não limitar o app ao uso na academia. Por
          que não fazer do BTFIT uma alternativa para quem não vai à academia?
          Por isso, os treinos são adaptáveis ao lugar aonde o usuário está,
          independente se tem equipamentos ou não. Deu vontade de ir para
          academia? Ótimo! O app te acompanha. Quer fazer um treino no parque
          para variar? Ele também está lá. Tudo isso para que diferentes perfis
          de pessoas consigam mudar suas rotinas.
        </p>
        <p i18n="@@Differential6">
          Agora que você conhece o BTFIT, experimente o app e comece a treinar
          com a gente!
        </p>
      </div>
      <div class="col-md-12 legal-publications-col">
        <bf-legal-publications></bf-legal-publications>
      </div>
    </div>
  </bf-modal>
</ng-template>
