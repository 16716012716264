import { AfterViewInit, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DataService, LanguageService } from "@services";
import { ModalService } from "@services/modal.service";

import { LanguageModalComponent } from "../language-modal/language-modal.component";

@Component({
  selector: "bf-landing-menu",
  templateUrl: "bf-landing-menu.component.html",
  styleUrls: [
    "./../bf-nav.component.scss",
    "./../bf-nav.component.responsive.scss",
  ],
})
export class LandingMenuComponent implements AfterViewInit {
  @Input() homeScreen = true;

  public ptBR: boolean;
  public redirectTo: string;
  public defaultProduct: string = null;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private modalService: ModalService
  ) {
    this.dataService.product.subscribe((defaultProduct) => {
      this.defaultProduct = defaultProduct;
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(
      ({ redirectTo }) => (this.redirectTo = redirectTo)
    );
  }

  showLangModal(): void {
    this.modalService.showModal(LanguageModalComponent);
  }

  isPtBR() {
    return this.languageService.isSetToPt();
  }

  setOffset(container: string, offset: number) {
    this.dataService.updatedOffsetHomePage({ container, offset });
  }
}
