import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

export interface CirclesForCarouselConfig {
  totalNumberOfCards: number;
  numberPerPage: {
    desktop: number;
    tablet: number;
    mobile: number;
  };
}

@Component({
  selector: "bf-circles-for-carousel",
  templateUrl: "circles-for-carousel.component.html",
  styleUrls: ["./circles-for-carousel.component.scss"],
})
export class CircleForCarouselComponent implements OnInit {
  @Input() selectedCircle = 0;

  @Input() slickModal: TemplateRef<any>;

  @Input() config: CirclesForCarouselConfig = {
    totalNumberOfCards: 12,
    numberPerPage: {
      desktop: 4,
      tablet: 2,
      mobile: 1,
    },
  };

  @ViewChild("containerOfCircles") container: any;

  desktopCircles: number[] = [];
  tabletCircles: number[] = [];
  mobileCircles: number[] = [];

  ngOnInit(): void {
    this.createCircles();
  }

  private createCircles() {
    const numberOfPagesDesktop =
      this.config.totalNumberOfCards / this.config.numberPerPage.desktop;
    const numberOfPagesTablet =
      this.config.totalNumberOfCards / this.config.numberPerPage.tablet;
    const numberOfPagesMobile =
      this.config.totalNumberOfCards / this.config.numberPerPage.mobile;
    this.desktopCircles = this.setupCirclesVectors(numberOfPagesDesktop);
    this.tabletCircles = this.setupCirclesVectors(numberOfPagesTablet);
    this.mobileCircles = this.setupCirclesVectors(numberOfPagesMobile);
  }

  private setupCirclesVectors(numberOfPages: number): number[] {
    const vectorOfCircles = [];

    for (let i = 0; i < numberOfPages; i++) {
      vectorOfCircles.push(i);
    }

    return vectorOfCircles;
  }
}
