import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

import { NgProgress, NgProgressRef } from "@ngx-progressbar/core";
import { Angulartics2GoogleTagManager } from "angulartics2/gtm";
import { Observable } from "rxjs";

import {
  AuthControllerService,
  ConfigurationService,
  COOKIEC_TOKEN,
  DataService,
  JQ_TOKEN,
  LanguageService,
  MapErrorService,
  MetaService,
  SMARTB_TOKEN,
  UserControllerService,
} from "@services";

@Component({
  selector: "bf-app",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  public routerEvents: Observable<Event>;
  @ViewChild("i18nAppLanguage") appLanguage: ElementRef;
  @ViewChild("i18nCookieConsentText") cookieConsentText: ElementRef;
  @ViewChild("i18nCookieConsentLink") cookieConsentLink: ElementRef;
  @ViewChild("i18nCookieConsentButton") cookieConsentButton: ElementRef;
  @ViewChild("i18nShareTitle") shareTitle: ElementRef;
  @ViewChild("i18nShareDesc") shareDesc: ElementRef;
  @ViewChild("i18nShareImgLink") shareImgLink: ElementRef;

  progressRef: NgProgressRef;

  constructor(
    private authService: AuthControllerService,
    private languageService: LanguageService,
    private progress: NgProgress,
    private meta: MetaService,
    private router: Router,
    private configurationService: ConfigurationService,
    private dataService: DataService,
    private mapErrorService: MapErrorService,
    private userControllerService: UserControllerService,
    // não remover a injeção do Angulartics2 abaixo. Ela faz com que os eventos de Page View sejam disparados para o GTM
    private gtm: Angulartics2GoogleTagManager,
    @Inject(DOCUMENT) private document: any,
    @Inject(JQ_TOKEN) private $: any,
    @Inject(COOKIEC_TOKEN) private cookieconsent: any,
    @Inject(SMARTB_TOKEN) private SmartBanner: any
  ) {
    this.authService.auth();

    // session log
    if (this.userControllerService.loggedIn()) {
      this.configurationService.sessionLog().subscribe(() => {});
    }
  }

  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.progressRef.start();
    }

    if (event instanceof NavigationEnd) {
      window.scrollTo(0, 0);
      this.progressRef.complete();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.progressRef.complete();
    }
    if (event instanceof NavigationError) {
      this.progressRef.complete();
    }
  }

  ngAfterViewInit() {
    const appLanguage = this.appLanguage.nativeElement.innerHTML;
    this.languageService.setLanguage(appLanguage);
  }

  ngOnInit(): void {
    this.routerEvents = this.router.events;
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
    this.progressRef = this.progress.ref();
  }

  @HostListener("window:load")
  onLoad() {
    this.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#2f3033",
          text: "#ffffff",
        },
        button: {
          background: "#46a1a2",
          text: "#ffffff",
        },
      },
      content: {
        message: this.cookieConsentText.nativeElement.innerHTML,
        link: this.cookieConsentLink.nativeElement.innerHTML,
        dismiss: this.cookieConsentButton.nativeElement.innerHTML,
      },
    });

    this.meta.setDefault([
      {
        property: "og:title",
        content: this.shareTitle.nativeElement.innerHTML,
      },
      {
        property: "og:description",
        content: this.shareDesc.nativeElement.innerHTML,
      },
      {
        property: "og:image",
        content: this.shareImgLink.nativeElement.innerHTML,
      },
      {
        itemprop: "name",
        content: this.shareTitle.nativeElement.innerHTML,
      },
      {
        itemprop: "description",
        content: this.shareDesc.nativeElement.innerHTML,
      },
      {
        itemprop: "image",
        content: this.shareImgLink.nativeElement.innerHTML,
      },
      {
        name: "twitter:title",
        content: this.shareTitle.nativeElement.innerHTML,
      },
      {
        name: "twitter:description",
        content: this.shareDesc.nativeElement.innerHTML,
      },
      {
        name: "twitter:image",
        content: this.shareImgLink.nativeElement.innerHTML,
      },
    ]);

    this.meta.useDefault();
  }

  public timRoute() {
    return this.router.url.includes("timfitness");
  }
}
